import { MediaInteractionViewerModels } from '@medsurf/flat-models';

// <editor-fold desc="Action Types">

export enum ActionTypes {
  SetEvents = '[STORE | VIEWER | MEDIA-INTERACTION] Set Events',
  ClearEvents = '[STORE | VIEWER | MEDIA-INTERACTION] Clear Events',
  SetCurrentTime = '[STORE | VIEWER | MEDIA-INTERACTION] Set Current Time',
  JumpToTime = '[STORE | VIEWER | MEDIA-INTERACTION] Jump To Time',
  TogglePlay = '[STORE | VIEWER | MEDIA-INTERACTION] Toggle Play',
  OpenModal = '[STORE | VIEWER | MEDIA-INTERACTION] Open Modal'
}

// </editor-fold>

// <editor-fold desc="Models">

// </editor-fold>

// <editor-fold desc="Store">

export class AddEvents {
  public static readonly type = ActionTypes.SetEvents;

  public constructor(public events: MediaInteractionViewerModels.MediaInteractionEvent[]) {
  }
}

export class ClearEvents {
  public static readonly type = ActionTypes.ClearEvents;
}

export class SetCurrentTime {
  public static readonly type = ActionTypes.SetCurrentTime;

  public constructor(public currentTime: number) {
  }
}

export class TogglePlay {
  public static readonly type = ActionTypes.TogglePlay;

  public constructor(public force?: 'play' | 'pause') {
  }
}

export class OpenModal {
  public static readonly type = ActionTypes.OpenModal;
  public constructor(public targetId: string, public onClose?: { type: 'play' | 'pause', value?: number }) {
  }
}


export class JumpToTime {
  public static readonly type = ActionTypes.JumpToTime;

  public constructor(public time: number, public force?: 'play' | 'pause') {
  }
}

// </editor-fold>

// <editor-fold desc="Request">

// </editor-fold>
